import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import {ImageLogo} from '../components/logo';

const BlueStyle = styled.span`
  color: #66CCCC;
`;

const CLink = styled.a`
  color: #66CCCC;
`;

const PreStyle = styled.div`
  margin: auto;
  width: 100%;
  font-size: 10px;
  white-space: pre-wrap;
  display: inline-block;
  font-family: 'Source Code Pro', Consolas, Menlo, Monaco, 'Lucida Console', 'Liberation Mono', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Courier New', monospace, sans-serif;
  letter-spacing: -0.1rem;
  line-height: 1.1 rem;
  color: #FFFFFF;
  text-shadow: 0 0 2px rgba(100,100,100,0.5);
  margin-left: 2 rem;
  margin-top: 2 rem;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
    width: 40rem;
  }
`;

// girlswhocode $104.60 USD
// Receipt Number	10445468
// New York Common Pantry $50.00 USD
// Receipt Number 380087631095842
const GivingBackPage = () => (
  <Layout>
    <SEO title='Giving Back - katelibby.tv - ' />
<br/>
<PreStyle>
/== <BlueStyle>COVID-19</BlueStyle> =================================================================\<br/>
|                                                                             |<br/>
| Our Organization, katelibby.tv being centered in the heart of Manhattan NYC |<br/>
| is fortunate enough to be safe during these times, but there are many who   |<br/>
| still need help. In a state of emergency, it takes a world —ALL OF US—      |<br/>
| to combat coronavirus.                                                      |<br/>
| We made a committment to generate 1,000$ to the CDC Foundation's            |<br/>
| coronavirus (COVID-19) emergency response fund, which deploys emergency     |<br/>
| staffing to public health agencies, funds medical supplies, increases       |<br/>
| lab capacity, provides support to vulnerable communities, and much more.    |<br/>
| To learn more or make a donation go to:                                     |<br/>
|        <CLink href='https://tiltify.com/@wh-iterabb-it/whiterabbit-campaign-for-cdc-covid-19-emergency-response-fund'>Our CDC Tiltify Page</CLink>                                                 |<br/>
|                                                                             |<br/>
\=============================================================================/<br/><br/>
</PreStyle>
  <br/>
  <PreStyle>
    <p>
    We had several iterations of <CLink href="https://github.com/wh-iterabb-it/katelibby">Katelibby</CLink> over the years. To better utilize a simple small bot that has been a part of our IRC community for the better part of a decade, we added the functionality to go on twitch, and host a raffle. We have in efforts of the bot's account generate donations for our favorite causes and impactful community programs, listed below:
    </p>

    <ul>
      <li><CLink href="https://girlswhocode.com/">girlswhocode.com</CLink> $104.60 USD</li>
      <li><CLink href="https://tiltify.com/@wh-iterabb-it/whiterabbit-campaign-for-cdc-covid-19-emergency-response-fund">CDC COVID-19 response fund</CLink> ($101/256.00) USD</li>
      <li><CLink href="https://secure3.convio.net/fbnyc/site/Donation2?df_id=9716&9716.donation=form1&mfc_pref=T&commas=yes">New York Common Pantry</CLink> $50.00 USD</li>
      <li><CLink href="https://tiltify.com/+wh-iterabb-it/whiterabbit-st-jude-children">Saint Jude Children's Research Hospital</CLink> $100.00 USD</li>
      <li><CLink href="https://tiltify.com/+wh-iterabb-it/whiterabbit-the-bail-fund">The Bail Fund</CLink> $100.00 USD</li>
    </ul>
  </PreStyle>
</Layout>
)

export default GivingBackPage
